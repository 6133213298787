<template>

    <div
        class="wrapperForm"
    >
        <v-row 
            no-gutters
        >

            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                <v-card 
                    flat
                >
                    <v-card-text>

                        <div class="form-box-title title-with-right-part">
                            <span>
                                {{ $t("Счетчики_поручений") }}
                            </span>
                            <div class="twrp-content">
                                <v-text-field
                                    :value="search"
                                    @input="updateSearch($event)"
                                    :placeholder="$t('Поиск')"
                                    hide-details
                                    dense
                                    clearable
                                    required
                                    @click:clear="search=''"
                                    class="twrp-search"
                                    append-icon="fas fa-search"
                                >
                                </v-text-field>
                            </div>
                        </div>

                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="entities"
                            :options.sync="options"
                            :server-items-length="total"
                            :items-per-page="10"
                            :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                            :page.sync="options.page"
                            @page-count="paginationPageCount = $event"
                            class="elevation-1 customization-list-table pagination-list-table sticky-table"
                            @dblclick:row="(event, data) => onRowClick({ id: data.item.id })"
                        >

                            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                            <template v-slot:[`item.name`]="{ item }">
                                <div class="elipsis-text-wrap">
                                    <div class="etw-text-container">
                                        <div class="etw-text" v-tooltip.top-center="item.name">
                                            {{item.name}}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:[`footer.prepend`]>
                                <v-pagination
                                    v-model="options.page"
                                    :length="paginationPageCount"
                                    :total-visible="$listPaginationTotal"
                                    color="teal"
                                ></v-pagination>
                            </template>

                        </v-data-table>

                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>        
    </div>

</template>

<script>
import i18n from '@/i18n';
import _ from 'lodash';
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';

export default {
    name: "OrderCounters",
    columnIndex: {
        'name': 2,
        'value': 3,
        'reserve': 4
    },
    props: {
        enterpriseId: {
            type: String,
            default: null
        }
    },
    computed: {
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        }
    },
    data: () => ({
        headers: [
            { text: i18n.t("Наименование"), value: 'name' },
            { text: i18n.t("Значение"), value: 'value', width: '10%' },
        ],
        loading: true,
        total: 0,
        entities: [],
        search: '',
        options: {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "name" ],
            sortDesc : [ false ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
        paginationPageCount: 0,
    }),
    methods: {
        ...mapActions(['setOverlayVisible']),
        async onRowClick(eventData) {
            try
            {
                let orderCounter = await this.$store.dispatch('dialogs/orderCounter/open', { counterId: eventData.id, enterpriseId: this.enterpriseId });
                this.setOverlayVisible({ visible: true, message: 'Сохранение...' });

                let saveResponse = await httpAPI({
                    url: `api/save?type=Orders.Counter&entid=${this.enterpriseId}`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ parent: orderCounter, childrens: null }) },
                });

                this.setOverlayVisible({ visible: false });

                if (saveResponse) {
                    await this.getData();
                    this.$notify.success(saveResponse.data.message);
                }

            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        updateSearch: _.debounce(function(v) {
            if (v !== null)
                this.search = v;
        }, 500),
        async getData() {
            this.total = 0;
            this.entities = [];
            this.loading = true;

            let response = await httpAPI({
                url: `api/settings/list?collection=Orders.Counter&entid=${this.enterpriseId}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET'
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "name" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 2;

            return {
                SearchStr,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            };
        }
    },
    async mounted() {
        await this.getData();
    },
    watch: {
        filter: {
            async handler(newVal, oldVal) {
                if (!this.$_.isEqual(newVal, oldVal)) {
                    console.log('filter changed');
                    await this.getData();
                }
            },
            deep: true,
        },
    }
}
</script>