<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        persistent
        max-width="900"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="loading">

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text v-else class="wrapperFormModal">
                <v-form ref="form" lazy-validation>
                    <v-card flat>
                        <v-card-text>
                            
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Родительский_элемент") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <treeselect
                                        :options="divisions"
                                        :value="parentDivision"
                                        @input="$store.commit('emsDialogs/addWorkplace/SET_PARENT_DIVISION', $event )"
                                        :multiple="false"
                                        :show-count="true"
                                        :searchable="true"
                                        search-nested
                                        placeholder=""
                                        :noResultsText="$t('Нет_результатов')"
                                        :noOptionsText="$t('Нет_результатов')"
                                        :clearable="false"
                                    >
                                        <div slot="value-label" slot-scope="{ node }">
                                            <template v-if="node.raw !== null">
                                                <template v-if="node.ancestors == null || node.ancestors.length === 0">
                                                    {{ node.raw.label }}
                                                </template>
                                                <template v-else>

                                                    <template v-for="(ancestor, index) in Array.from(node.ancestors).reverse()">
                                                        <span
                                                            :key="ancestor.id"
                                                        >      
                                                            {{ ancestor.label.length > 15 ? `${ancestor.label.substring(0, 14)}...` : ancestor.label }}
                                                        </span>
                                                        <v-icon
                                                            :key="index"
                                                            small
                                                        >
                                                            fas fa-angle-right
                                                        </v-icon>
                                                    </template>

                                                    {{ node.raw.label }}

                                                </template>
                                            </template>
                                        </div>

                                        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                            <v-icon
                                                v-if="node.isBranch"
                                                x-small
                                            >
                                                fas fa-folder
                                            </v-icon>
                                            <v-icon
                                                v-else
                                                x-small
                                            >
                                                far fa-folder
                                            </v-icon>
                                            <span v-tooltip.top-center="node.label">
                                                {{ node.label }}
                                            </span>
                                            <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                                        </label>

                                    </treeselect>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Должность") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-autocomplete
                                        :value="position"
                                        @input="$store.commit('emsDialogs/addWorkplace/SET_POSITION', $event )"
                                        :items="positions"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        hide-details
                                        outlined
                                        dense
                                        :rules="requiredRule"
                                        append-icon="fas fa-chevron-down"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{ $t("Индекс") }} 
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="index"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="!loading && parentDivision && position"
                >
                    {{ $t("Сохранить") }}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    name: "AddWorkplaceDlg",
    components: {
        Treeselect
    },
    data: () => ({
        title: "Рабочее_место",
    }),
    computed: {
        ...mapGetters('emsDialogs/addWorkplace',
        {
            visible: 'isVisible',
            loading: 'isLoading',
            divisions: 'getDivisions',
            parentDivision: 'getParentDivision',
            position: 'getPosition',
            positions: 'getPositions',
            getIndex: 'getIndex'
        }),
        ...mapGetters({ requiredRule: 'getRequiredRule'}),
        index: {
            get: function() {
                return this.getIndex;
            },
            set: _.debounce(function(value) {
                this.$store.commit('emsDialogs/addWorkplace/SET_INDEX', value );
            }, 500)
        },
    },
    methods: {
        ...mapActions('emsDialogs/addWorkplace', [ 'ok', 'cancel' ]),
        getDepartmentFullName(node, delimeter) {            
            if (!node.ancestors?.length)
                return node.raw.label;

            let ancestorsPath = Array
                .from(node.ancestors)
                .reverse()
                .map(x => x.label)
                .filter(Boolean)
                .join(delimeter);
                
            return `${ancestorsPath}${delimeter}${node.raw.label}`;
        },
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>