<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Файл_импорта") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group">

                                        <div @click="$refs.atachmentsRef.click()"
                                            v-if="!importing && importedLines == 0"
                                        >
                                            <input
                                                type="file"
                                                ref="atachmentsRef"
                                                @change="onFilesPicked"
                                                style="display: none"
                                                accept=".csv"
                                            />
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        
                                        <div class="cloud-file-list-box">
                                            <div class="cloud-items-container">

                                                <div v-if="!attachments || attachments.length == 0" class="cfl-placeholder">{{ $t('Нет_файлов') }}</div>

                                                <v-row 
                                                    v-else
                                                    dense
                                                >
                                                    <v-file-item
                                                        v-for="attachment in attachments" :key="attachment.name"
                                                        :name="attachment.name"
                                                        :size="attachment.size"
                                                        col-width="12"
                                                        :hide-load="true"
                                                        :is-read-only="true"
                                                    />
                                            </v-row>

                                            </div>
                                        </div>                
                                        

                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" xs="12" md="12">
                                    <v-textarea
                                        no-resize
                                        readonly
                                        rows="3"
                                        :value="errorsText"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <!--#endregion-->   
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions
                    v-if="!importing"
                >
                    <v-spacer></v-spacer>
                    <v-btn 
                        v-if="importedLines!=0"
                        color="cyan" 
                        text 
                        depressed 
                        @click="save" 
                        >
                        {{$t("Ok")}}
                    </v-btn>
                    <v-btn 
                        v-if="importedLines==0"
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "ImportStructDlg",
    data () {
        return {
            title: i18n.t("Импорт_штатного_расписания"),
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            attachments: [],
            totalLines: 0,
            importedLines: 0,
            importedErrors: 0,
            errors: [],
            entId:'',
            importing: false
        }
    },
    computed: {
        errorsText() { return this.errors.join('\r\n'); }
    },
    methods: {
        async open(id)
        {
            this.entId = id;
            this.attachments = [];
            this.totalLines = 0;
            this.importedLines = 0;
            this.importedErrors = 0;
            this.errors = [];
            this.importing = false;
            this.visible = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async save() {
            this.visible = false;
            this.resolve(true);
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие отменено"
            });
        },
        async onFilesPicked(e) {
            this.loading = true;
            let files = e.target.files;
            this.attachments = Array.from(files);
            var reader = new FileReader();
            reader.readAsText(files[0])
            reader.onload = async () => {
                this.importing = true;
                let lines = reader.result.split(/\r?\n/);
                lines.splice(0, 1);
                this.totalLines = lines.length;
                this.importedLines = 0;
                this.errors = [];
                for(var i= 0; i<lines.length; i++){
                    if (lines[i]){
                        let elements = lines[i].split(';');
                        try {
                            let element = {
                                parentid:this.entId,
                                dep:elements[0].trim(),
                                jobtitle:elements[1].trim(),
                                firstname:elements[2].trim(), 
                                lastname:elements[3].trim(), 
                                middlename:elements[4].trim(), 
                                birthdate:elements[5].trim(), 
                                phone:elements[6].trim(), 
                                email:elements[7].trim()
                            }
                            let response = await httpAPI({
                                skipErrorHandler: true,
                                url: `/api/ems/importstruct`,
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                data: { Content : JSON.stringify({entid:this.entId, element:element}) },
                            });
                            if (response && response.data.payload.Result != 'ERROR'){
                                this.importedLines ++;
                                let fio = `${element.firstname} ${element.lastname} ${element.middlename}`.trim().padEnd(40,' ');
                                this.errors.push(`${fio} - Ok`)
                            }
                            else{
                                this.importedErrors ++;
                                this.errors.push(response?.data?.payload?.Message)
                            } 
                                
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }
                    else 
                        this.totalLines --;
                    
                }
                this.errors.push(`Импорт выполнен.`);
                this.errors.push(`Импортировано ${this.importedLines} записей из ${this.totalLines}.`);
                this.errors.push(`Ошибок ${this.importedErrors}`);
                this.importing = false;
            };
            /*reader.onloadend = function(evt) {      
                let lines = evt.target.result.split(/\r?\n/);
                this.fillEntities(lines);
            };
            let file = e.target.files[0];
            this.loading = true;
            this.entities = await reader.readAsText(file) ?? [];
            this.total = this.entities.length;
            this.loading = false;
            console.log('total', this.total);
            console.log('items', this.entities);*/
            /*let passAttachments = [];
            let skipAttchments = [];
            let totalSize = 0;

            for (let attachment of attachments) {
                let checkResult = await this.validateFile(attachment);

                if (!checkResult.success) {
                    skipAttchments.push({ message: checkResult.message, attachment });
                    continue;
                }

                if (passAttachments.length == 5) {
                    skipAttchments.push({ message: this.$t('Превышен_лимит_количества_вложений'), attachment });
                    continue;
                }

                if (totalSize + attachment.size > this.MaxFilesSize) {
                    skipAttchments.push({ message: this.$t('Превышен_лимит_размера_вложений'), attachment });
                    continue;
                }

                totalSize += attachment.size;
                passAttachments.push(attachment);
            }

            skipAttchments.forEach(obj => {
                this.$notify.alert(this.$t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: obj.attachment.name, reason: obj.message }));
            });

            this.$store.commit('dialogs/euolAddExecution/SET_ATTACHMENTS', passAttachments);
            this.$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'HasAttachments', value: passAttachments.length > 0 });

            e.target.value = '';*/
        },
        /*onDeleteAttachment() {
            let index = this.attachments.indexOf(attachment);
            let attachments = Array.from(this.attachments);
            attachments.splice(index, 1);
            this.$store.commit('dialogs/euolAddExecution/SET_ATTACHMENTS', attachments);
            this.$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'HasAttachments', value: attachments.length > 0 });
            
        },*/
    }
}
</script>