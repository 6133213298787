<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        persistent
        max-width="900"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="loading">

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text v-else class="wrapperFormModal">
                <v-form ref="form" lazy-validation>
                    <v-card flat>
                        <v-card-text>

                            <!--поле Отдел-->
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Родительский_элемент") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <treeselect
                                        :options="divisions"
                                        :value="parentDivision"
                                        @input="onParentDivisionChange"
                                        :multiple="false"
                                        :show-count="true"
                                        :searchable="true"
                                        search-nested
                                        placeholder=""
                                        :noResultsText="$t('Нет_результатов')"
                                        :noOptionsText="$t('Нет_результатов')"
                                        :clearable="false"
                                    >
                                        <div slot="value-label" slot-scope="{ node }">
                                            <template v-if="node.raw !== null">
                                                <template v-if="node.ancestors == null || node.ancestors.length === 0">
                                                    {{ node.raw.label }}
                                                </template>
                                                <template v-else>

                                                    <template v-for="(ancestor, index) in Array.from(node.ancestors).reverse()">
                                                        <span
                                                            :key="ancestor.id"
                                                        >      
                                                            {{ ancestor.label.length > 15 ? `${ancestor.label.substring(0, 14)}...` : ancestor.label }}
                                                        </span>
                                                        <v-icon
                                                            :key="index"
                                                            small
                                                        >
                                                            fas fa-angle-right
                                                        </v-icon>
                                                    </template>

                                                    {{ node.raw.label }}

                                                </template>
                                            </template>
                                        </div>

                                        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                            <v-icon
                                                v-if="node.isBranch"
                                                x-small
                                            >
                                                fas fa-folder
                                            </v-icon>
                                            <v-icon
                                                v-else
                                                x-small
                                            >
                                                far fa-folder
                                            </v-icon>
                                            <span v-tooltip.top-center="node.label">
                                                {{ node.label }}
                                            </span>
                                            <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                                        </label>

                                    </treeselect>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{ $t("Наименование") }} 
                                        <span>({{ $t("каз._яз.") }})</span>
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="nameKZ"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="requiredRule"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{ $t("Наименование") }} 
                                        <span>({{ $t("рус._яз.") }})</span>
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="nameRU"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="requiredRule"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{ $t("Индекс") }} 
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="index"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="!isNew">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Руководитель") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <div class="icon-click-row-group icrg-align-center">
                                        <div @click="onHeadSelect">
                                            <v-icon small v-tooltip.left-center="selectHeadTooltip">
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        <div v-if="headId!='00000000-0000-0000-0000-000000000000'" class="onlyReadData">
                                            <v-workplace-chip 
                                                :id="headId"
                                                :name="getEmployeeName(headId)"
                                                :canDelete="true"
                                                :delFunc="delHead"
                                            />
                                        </div>
                                        <div v-else class="onlyReadData">
                                            {{$t("Не_указано")}} 
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="!isNew">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Заместители") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <div class="icon-click-row-group icrg-align-center">
                                        <div @click="onDeputiesSelect">
                                            <v-icon small v-tooltip.left-center="selectDeputiesTooltip">
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        <div v-if="deputiesids.length > 0" class="onlyReadData">
                                            <v-workplace-chip
                                                v-for="empid in deputiesids"
                                                :key="empid" 
                                                :id="empid"
                                                :name="getEmployeeName(empid)"
                                                :canDelete="true"
                                                :delFunc="delDeputie"
                                            />
                                        </div>
                                        <div v-else class="onlyReadData">
                                            {{$t("Не_указано")}} 
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="!isNew">
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Полномочия_руководителя") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <div class="icon-click-row-group icrg-align-center">

                                        <div @click="onSpecialPowerSelect">
                                            <v-icon small v-tooltip.left-center="selectSpecialPowerTooltip">
                                                fas fa-edit
                                            </v-icon>
                                        </div>

                                        <div v-if="specpowerids.length > 0" class="onlyReadData more-per-lab-wrap">
                                            <v-workplace-chip
                                                v-for="empid in specpowerids"
                                                :key="empid" 
                                                :id="empid"
                                                :name="getEmployeeName(empid)"
                                                :canDelete="true"
                                                :delFunc="delSpecPower"
                                            />
                                        </div>
                                        <div v-else class="onlyReadData">
                                            {{$t("Не_указано")}} 
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="nameKZ && nameRU && parentDivision"
                >
                    {{ $t("Сохранить") }}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    name: "EMSAddSubDivisionDlg",
    components: {
        Treeselect
    },
    data: () => ({
        title: "Подразделение",
        dlgSelectEmplyeeTitle: {
                1:"Выбор_руководителя",
                2:"Выбор_заместителей",
                3:"Выбор_сотрудников_с_полномочиями_руководителя"
        },
    }),
    computed: {
        ...mapGetters('emsDialogs/addSubDivision',
        {
            visible: 'isVisible',
            loading: 'isLoading',
            name: 'getName',
            divisions: 'getDivisions',
            parentDivision: 'getParentDivision',
            isNew: 'isNew',
            headId: 'getHeadId',
            employees: 'getEmployees',
            deputiesids: 'getDeputies',
            specpowerids: 'getSpecPowers',
            getIndex: 'getIndex'
        }),
        ...mapGetters({ requiredRule: 'getRequiredRule'}),
        selectDeputiesTooltip(){
            return this.$t("Выберите_заместителей");
        },
        selectHeadTooltip(){
            return this.$t("Выберите_руководителя");
        },
        selectSpecialPowerTooltip(){
            return this.$t("Выберите_сотрудников_с_особыми_полномочиями");
        },
        nameRU: {
            get: function() {
                return this.name.ru;
            },
            set: _.debounce(function(value) {
                this.$store.commit('emsDialogs/addSubDivision/SET_NAME', { locale: 'ru', value });
            }, 500)
        },
        nameKZ: {
            get: function() {
                return this.name.kz;
            },
            set: _.debounce(function(value) {
                this.$store.commit('emsDialogs/addSubDivision/SET_NAME', { locale: 'kz', value });
            }, 500)
        },
        index: {
            get: function() {
                return this.getIndex;
            },
            set: _.debounce(function(value) {
                this.$store.commit('emsDialogs/addSubDivision/SET_INDEX', value );
            }, 500)
        },
    },
    methods: {
        ...mapActions('emsDialogs/addSubDivision', [ 'ok', 'cancel' ]),
        async onEmployeeSelect(selecthead){
            let selectElement = null;
            let sourceElements = null;
            switch(selecthead){
                case 1:
                    selectElement = [this.headId];
                    sourceElements = this.employees.map( x =>
                    [ 
                        x.id, 
                        x.name, 
                        null, 
                        x.jobtitle
                    ]);
                    break;
                case 2:
                    selectElement = this.deputiesids;
                    sourceElements = this.employees.filter(x=>x.id!=this.headId).map( x =>
                    [ 
                        x.id, 
                        x.name, 
                        null, 
                        x.jobtitle
                    ]);
                    break;
                case 3:
                    selectElement = this.specpowerids;
                    sourceElements = this.employees.filter(x=>!x.hasparentspecpower && !x.hasparentleadership && x.id!=this.headId && !this.deputiesids.find(z=>z==x.id)).map( x =>
                    [ 
                        x.id, 
                        x.name, 
                        null, 
                        x.jobtitle
                    ]);
                    break;
            }
            let selectMemberParams =  {
                title: this.dlgSelectEmplyeeTitle[selecthead],
                fromSource: true,
                source: sourceElements,
                multiple: selecthead!=1,
                selected: selectElement,
            }

            try
            {
                
                let selectedPerson = await this.$store.dispatch('dialogs/selectMembers/open', selectMemberParams);
                let idx = -1;
                switch(selecthead){
                    case 1:
                        this.$store.commit('emsDialogs/addSubDivision/SET_HEAD_ID', selectedPerson.workplaceId);
                        idx = this.deputiesids.indexOf(this.headId);
                        if (idx >= 0)
                            this.deputiesids.splice(idx, 1);
                        this.$store.commit('emsDialogs/addSubDivision/SET_DEPUTIES_IDS', this.deputiesids);
                        idx = this.specpowerids.indexOf(this.headId);
                        if (idx >= 0)
                            this.specpowerids.splice(idx, 1);
                        this.$store.commit('emsDialogs/addSubDivision/SET_SPECPOWERES_IDS', this.specpowerids);
                        break;
                    case 2:
                        this.$store.commit('emsDialogs/addSubDivision/SET_DEPUTIES_IDS', selectedPerson.map(x => x.workplaceId));
                        this.$store.commit('emsDialogs/addSubDivision/SET_SPECPOWERES_IDS', this.specpowerids.filter(x=>!this.deputiesids.find(y=>y==x)));
                        break;
                    case 3:
                        this.$store.commit('emsDialogs/addSubDivision/SET_SPECPOWERES_IDS', selectedPerson.map(x => x.workplaceId));
                        break;
                }
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onHeadSelect(){
            await this.onEmployeeSelect(1);
        },
        async onDeputiesSelect(){
            await this.onEmployeeSelect(2);
        },
        async onSpecialPowerSelect(){
            await this.onEmployeeSelect(3);
        },
        async delHead(){
            this.$store.commit('emsDialogs/addSubDivision/SET_HEAD_ID', null);
        },
        async delDeputie(workplace_id){
            this.deputiesids.splice(this.deputiesids.indexOf(workplace_id), 1);
            this.$store.commit('emsDialogs/addSubDivision/SET_DEPUTIES_IDS', this.deputiesids);
        },
        async delSpecPower(workplace_id){
            this.specpowerids.splice(this.specpowerids.indexOf(workplace_id), 1);
            this.$store.commit('emsDialogs/addSubDivision/SET_SPECPOWERES_IDS', this.specpowerids);
        },
        getEmployeeName(id) {
            return this.employees.find(x => x.id === id)?.name;
        },
        onParentDivisionChange(value) {
            this.$store.commit('emsDialogs/addSubDivision/SET_PARENT_DIVISION', value );
        },
        getDepartmentName(node) {
            return node.raw.label;
        },
        getDepartmentFullName(node, delimeter) {            
            if (!node.ancestors?.length)
                return node.raw.label;

            let ancestorsPath = Array
                .from(node.ancestors)
                .reverse()
                .map(x => x.label)
                .filter(Boolean)
                .join(delimeter);
                
            return `${ancestorsPath}${delimeter}${node.raw.label}`;
        },
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>