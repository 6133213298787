<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <v-card flat>
                        <v-card-text>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Замещающий") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <div v-if="substantein_in_process" class="onlyReadData">
                                        {{ substantein.name }}
                                    </div>
                                    <div v-else class="icon-click-row-group icrg-align-center">
                                        <v-autocomplete
                                            :value="substantein"
                                            @input="setSubstantein"
                                            :items="employees"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            hide-details
                                            outlined
                                            dense
                                            append-icon="fas fa-chevron-down"
                                        >
                                        </v-autocomplete>
                                        <!--<div @click="onSubStanteinSelect">
                                            <v-icon small v-tooltip.left-center="$t('Выбор_замещающего')">
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        <div v-if="substantein && substantein.workplaceId" class="onlyReadData">
                                            <v-workplace-chip 
                                                :id="substantein.workplaceid"
                                                :name="substantein.name"
                                            />
                                        </div>
                                        <div v-else class="onlyReadData">
                                            {{$t("Не_указано")}} 
                                        </div>-->
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Дата_начала_замещения") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <div v-if="substantein_in_process" class="onlyReadData">
                                        {{ DateStartModel | formattedDate }}
                                    </div>
                                    <v-menu 
                                        v-else
                                        v-model="DateMenuStart"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="DateStartModel | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                @click:clear="DateStartModel = null"
                                                class="datepick-input"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker 
                                            v-model="DateStartModel"
                                            @input="DateMenuStart = false"
                                            color="teal"
                                            :min="ToDayDate"
                                            :max="DateEndModel"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Дата_окончания_замещения") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-menu 
                                        v-model="DateMenuEnd"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="DateEndModel | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                @click:clear="DateEndModel = null"
                                                class="datepick-input"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker 
                                            v-model="DateEndModel"
                                            @input="DateMenuEnd = false"
                                            color="teal"
                                            :min="DateStartModel"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            
                        </v-card-text>
                    </v-card>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed 
                        v-if="substantein && DateStartModel && DateEndModel"
                        @click="ok" 
                    >
                        {{substantein_in_process ? $t("Продлить замещение") : $t("Установить замещающего")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import sys from '@/services/system';
import { mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "SetSubstanteinDlg",
    data () {
        return {
            title: i18n.t("Выбор_замещающего"),
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            workplaceid: null,
            substantein_id: null,
            substantein: null,
            ToDayDate: null,
            DateMenuStart: false,
            DateStartModel: null,
            DateMenuEnd: false,
            DateEndModel: null,
            employees: [],
        }
    },
    computed: {
        substantein_in_process(){
            return this.substantein_id && new Date(this.DateStartModel)<=new Date(this.ToDayDate) && new Date(this.DateEndModel)>new Date(this.ToDayDate);
        },
    },
    filters: {
        formattedDate: function (value) {
            var formattedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formattedDate ?? "";
        }
    },
    methods: {
        ...mapActions('references', ['getTimestamp']),
        async open(forwp_id, selected_substantein, ent_id, enddate, options = {})
        {
            this.reset();
            this.ToDayDate = await this.getTimestamp();
            this.visible = true;
            this.search = null;
            this.options = Object.assign(this.options, options);
            this.workplaceid = forwp_id;
            if (selected_substantein){
                this.substantein_id =  selected_substantein.workplaceid;
                this.DateStartModel = selected_substantein.fromdate.substring(0,10);
                this.DateEndModel = selected_substantein.todate.substring(0,10);
            }
            else {
                this.DateStartModel = this.ToDayDate.substring(0,10);
                if (enddate){
                    this.DateEndModel = enddate.substring(0,10);
                }
            }
            let response = await httpAPI({
                url: `api/ems/workplaceslist`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content : JSON.stringify({
                    onlyBusy : true, 
                    enterprise_id: ent_id,
                    workplace_id: this.workplaceid
                }) },
            });
            if (response){
                let empl_list = response.data.payload.data;
                this.employees = empl_list.map(x=>{return {workplaceId:x[0], name:x[1]};}).filter(x=>x.workplaceId!=this.workplaceid);
                this.substantein = this.employees.find(x => x.workplaceId==this.substantein_id);
            }
            
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие отменено"
            });
        },
        ok() {
            this.visible = false;
            this.resolve({ param: this.getResultObject() });
        },
        getResultObject() {
            return { substanteinid: this.substantein.workplaceId, from: `/Date(${ Date.parse(this.DateStartModel) })/`, to: `/Date(${ Date.parse(this.DateEndModel) })/` };
        },
        reset() {
            this.DateStartModel = null;
            this.DateEndModel = null;
            this.substantein = null;
        },
        setSubstantein(item){
            this.substantein = item;
        },
    }
}
</script>