<template>
    <div class="p-relative" v-if="dataSource">
        
        <Toolbar 
            :menu="menu"
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <div class="white-panel-items">
            <div class="wp-row">
                <div class="info-panel-text info-panel-text-uppercase"><b>{{ $t("Организация") }} {{isNewRecord ? "Новая" : localizedName}}</b></div>
            </div>
        </div>

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab
                v-for="item, index in tabs"
                :key="index"
                @click="onTabClicked(item)"
            >
                {{ $t(item.name) }}
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >

            <v-tab-item
                v-for="item, index in tabs"
                :key="index" 
            >
                <!-- Карточка документа -->
                <v-form
                    v-if="item.type == 'Card'"
                    class="wrapperForm"
                    ref="form"
                    lazy-validation
                >
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">

                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Общая_информация") }}</div>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label f-label-more-rows">
                                                {{ $t("Наименование") }} 
                                                <span>({{ $t("каз._яз.") }})</span>
                                            </label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">

                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Enterprise.namekz ? dataSource.Enterprise.namekz : $t("Не_указано") }}
                                            </div>

                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Enterprise.namekz"
                                                hide-details
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label f-label-more-rows">
                                                {{ $t("Наименование") }} 
                                                <span>({{ $t("рус._яз.") }})</span>
                                            </label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">

                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Enterprise.nameru ? dataSource.Enterprise.nameru : $t("Не_указано") }}
                                            </div>

                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Enterprise.nameru"
                                                hide-details
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("БИН") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Enterprise.bin ? dataSource.Enterprise.bin : $t("Не_указано") }}
                                            </div>

                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Enterprise.bin"
                                                hide-details
                                                outlined
                                                dense
                                                :rules="iinRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Тип_организации") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">

                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Enterprise.type ? $options.enterpriseTypes.find(x => x.id == dataSource.Enterprise.type).name : $t("Не_указано") }}
                                            </div>

                                            <v-autocomplete
                                                v-else
                                                v-model="dataSource.Enterprise.type" 
                                                :items="$options.enterpriseTypes" 
                                                item-text="name" 
                                                item-value="id"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                                append-icon="fas fa-chevron-down"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card flat v-if="!isNewRecord">
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Руководство") }}</div>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Руководитель") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group icrg-align-center">
                                                <div v-if="!isViewMode" @click="onHeadSelect">
                                                    <v-icon small v-tooltip.left-center="selectHeadTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <div v-if="dataSource.Enterprise.headid!='00000000-0000-0000-0000-000000000000'" class="onlyReadData">
                                                    <v-workplace-chip
                                                        v-if="getEmployeeName(dataSource.Enterprise.headid)"
                                                        :id="dataSource.Enterprise.headid"
                                                        :name="getEmployeeName(dataSource.Enterprise.headid)"
                                                        :canDelete="!isViewMode"
                                                        :delFunc="delHead"
                                                    />
                                                    <div v-else class="onlyReadData">
                                                        {{$t("Вакансия")}} 
                                                    </div>
                                                </div>
                                                <div v-else class="onlyReadData">
                                                    {{$t("Не_указано")}} 
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Заместители") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group icrg-align-center">

                                                <div v-if="!isViewMode" @click="onDeputiesSelect">
                                                    <v-icon small v-tooltip.left-center="selectDeputiesTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>

                                                <div v-if="dataSource.Enterprise.deputiesids.length > 0" class="onlyReadData more-per-lab-wrap">
                                                    <v-workplace-chip
                                                        v-for="empid in dataSource.Enterprise.deputiesids"
                                                        :key="empid" 
                                                        :id="empid"
                                                        :name="getEmployeeName(empid)"
                                                        :canDelete="!isViewMode"
                                                        :delFunc="delDeputie"
                                                    />
                                                </div>
                                                <div v-else class="onlyReadData">
                                                    {{$t("Не_указано")}} 
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card flat v-if="!isNewRecord">
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Особые_полномочия") }}</div>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Полномочия_руководителя") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="icon-click-row-group icrg-align-center">

                                                <div v-if="!isViewMode" @click="onSpecialPowerSelect">
                                                    <v-icon small v-tooltip.left-center="selectSpecialPowerTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>

                                                <div v-if="dataSource.Enterprise.specpowerids.length > 0" class="onlyReadData more-per-lab-wrap">
                                                    <v-workplace-chip
                                                        v-for="empid in dataSource.Enterprise.specpowerids"
                                                        :key="empid" 
                                                        :id="empid"
                                                        :name="getEmployeeName(empid)"
                                                        :canDelete="!isViewMode"
                                                        :delFunc="delSpecPower"
                                                    />
                                                </div>
                                                <div v-else class="onlyReadData">
                                                    {{$t("Не_указано")}} 
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Обмен_документами") }}</div>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Идентификатор") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Enterprise.id ? dataSource.Enterprise.id : $t("Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Атлас_ID") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Enterprise.atlasid ? dataSource.Enterprise.atlasid : $t("Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("ЕУОЛ_код") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">

                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Enterprise.euolcode ? dataSource.Enterprise.euolcode : $t("Не_указано") }}
                                            </div>

                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Enterprise.euolcode"
                                                hide-details
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Лицензии") }}</div>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Число_рабочих_мест") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">{{ employees ? employees.length : 0 }}/{{ dataSource.Enterprise.countworkplace }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Контакты") }}</div>
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Контакты") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="10">
                                            <div class="onlyReadData more-per-lab-wrap">
                                                <template v-if="dataSource.Enterprise.contacts.length > 0">
                                                    <v-contact-item
                                                        v-for="contact in dataSource.Enterprise.contacts"
                                                        :key="contact.id"
                                                        :type="contact.type"
                                                        :icon="contact.icon"
                                                        :title="contact.value"
                                                        :description="contact.description"
                                                        :hide-load="true"
                                                        :is-read-only="isViewMode"
                                                        @editcontact="editContact" 
                                                        @deletecontact="deleteContact"
                                                    />
                                                </template>
                                                <template  v-else-if="isViewMode">
                                                        {{$t("Не_указано")}} 
                                                </template>
                                                <template>
                                                    <v-chip
                                                        class="person-label immutable-text"
                                                        small
                                                        label
                                                        outlined
                                                        v-if="!isViewMode"
                                                        @click="onAddContact()"
                                                    >
                                                        <i class="fas fa-plus dark-gray-color"></i>
                                                        <div>{{ $t("Добавить_контакт") }}</div>
                                                    </v-chip>
                                                </template>
                                            </div>
                                        </v-col>
                                    </v-row>    
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>

                <!-- Структура -->
                <div
                    v-else-if="item.type == 'Structure'"
                    class="wrapperForm"
                >

                    <v-row dense>
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title title-with-right-part">
                                        <span>{{ $t("Структура_организации") }}</span>
                                        <div class="twrp-content">

                                            <v-text-field                                                
                                                :placeholder="$t('Поиск')"
                                                hide-details
                                                dense
                                                clearable
                                                :value="departmentsTreeSearch"
                                                @input="onDepartmentTreeSearchChanged($event)"
                                                class="twrp-search mr-3"
                                                append-icon="fas fa-search"
                                            >
                                            </v-text-field>
                                            
                                            <div class="twrp-action" @click="toggleDepartmentsTree">
                                                <v-icon role="button" small left>
                                                    {{ departmentsTreeOpenAll ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
                                                </v-icon>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="resol-tree-wrapper">
                                        <div class="resol-tree-header">
                                            <div style="width: 20%;">{{ $t("Подразделение") }}, {{$t("Должность")}}</div>
                                            <div style="width: 10%;">{{ $t("ФИО") }}</div>
                                            <div style="width: 10%;">{{ $t("Замещающий") }}</div>
                                            <div style="width: 27%;">{{ $t("Контакты") }}</div>
                                            <div style="width: 10%;">{{ $t("Пользователь") }}</div>
                                            <div style="width: 15%;">{{ $t("Роль") }}</div>
                                            <div style="width: 8%;">{{ $t("Полномочия") }}</div>
                                            <div class="resol-tree-action-col"></div>
                                        </div>
                                        <DepartmentsTreeView
                                            :items="filteredStruct"
                                            :openAll="departmentsTreeOpenAll"
                                            :query="departmentsTreeSearch"
                                            @on-selected-change="onDepartmentsTreeSelectedChange"
                                            @on-item-click="onDepartmentsTreeItemClick"
                                            @on-item-dbl-click="onDepartmentsTreeItemDblClick"
                                            @on-invoke-action="onDepartmentsTreeItemActionInvoked"
                                            @on-tree-item-transfer="onStructItemTransfer"
                                        />
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                </div>

                <!-- Вкладки справочников -->
                <component
                    v-else
                    :is="item.type"
                    :ref="item.type"
                    :enterprise-id="dataSource.Enterprise.id"
                />

            </v-tab-item>

        </v-tabs-items>

        <AddSubDivisionDlg />
        <AddWorkplaceDlg />

        <ContactsDlg ref="ContactsRef"
            @on-save-contact="saveContact"
        />

        <ImportNomenclatureDlg ref="ImportNomenclatureRef"/>
        <ImportStructDlg ref="ImportStructRef"/>
        <SetSubstanteinDlg ref="SetSubstanteinDlgRef" />
    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import { mapActions, mapGetters } from 'vuex';
import { httpAPI } from "@/api/httpAPI";
import DepartmentsTreeView from '@/components/ems/DepartmentsTreeView.vue'
import AddSubDivisionDlg from '@/components/ems/dialogs/AddSubDivisionDlg.vue'
import AddWorkplaceDlg from '@/components/ems/dialogs/AddWorkplaceDlg.vue'
import ContactsDlg from '@/components/dialogs/ContactsDlg'
import ImportNomenclatureDlg from '@/components/dialogs/ImportNomenclatureDlg'
import ImportStructDlg from '@/components/dialogs/ImportStructDlg'
import SetSubstanteinDlg from '@/components/ems/dialogs/SetSubstanteinDlg.vue';
import _ from 'lodash';
import i18n from '@/i18n';
//import sys from '@/services/system';

//#region Импорт вкладок (бывш. раздел "Справочники")
import Counters from './settings-tabs/Counters.vue';
import EuolCounters from './settings-tabs/EuolCounters.vue';
import ExecuterGroups from './settings-tabs/ExecuterGroups.vue';
import Nomenclatures from './settings-tabs/Nomenclatures.vue';
import OrdersCounters from './settings-tabs/OrdersCounters.vue';
//#endregion

const filterTree = (search, list) => {
    return _.filter(list, (item) => {
        if (String(item.name).toLowerCase().includes(String(search).toLowerCase()) ||
            item.jobtitle && String(item.jobtitle).toLowerCase().includes(String(search).toLowerCase()) ||
            item.login && String(item.login).toLowerCase().includes(String(search).toLowerCase()))
        {
            return true;
        }
        else if (item.children) {
            item.children = filterTree(search, item.children);
            return !_.isEmpty(item.children);
        }
    });
};

export default {
    name: "EMSEnterpriseCard",
    components: {
        Toolbar,
        DepartmentsTreeView,
        AddSubDivisionDlg,
        AddWorkplaceDlg,
        ContactsDlg,
        ImportNomenclatureDlg,
        Counters,
        EuolCounters,
        ExecuterGroups,
        Nomenclatures,
        OrdersCounters,        
        ImportStructDlg,
        SetSubstanteinDlg,
    },
    enterpriseTypes: [
        { id: 1, name: "Министерство"},
        { id: 2, name: "Областные_департаменты,_управления_и_комитеты"},
        { id: 3, name: "Акиматы_районов_и_города"},
        { id: 4, name: "Областные_ТОО,_АО,_ОАО"},
    ],
    props: {
        id: { type: String, required: true }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            iinRule: 'getIinRule',
            integerRule: 'getIntegerRule'
        }),
        ...mapGetters('auth', ['getUserInfo']),
        tab: {
            get: function() {
                return this.$store.getters['emsCards/enterpriseCard/getActiveTab'];
            },
            set: function(value) {
                this.$store.commit('emsCards/enterpriseCard/SET_ACTIVE_TAB', value );
            }
        },
        tabs() {
            let tabs = [{ name: 'Карточка', type: 'Card' }];

            if (!this.isNewRecord) {
                tabs.push({ name: 'Структура', type: 'Structure' });

                if (this.isViewMode && this.dataSource.Enterprise.hasshema) {
                    tabs.push({ name: 'Номенклатура', type: 'Nomenclatures' });
                    tabs.push({ name: 'Журналы_регистрации', type: 'Counters' });
                    tabs.push({ name: 'Журналы_регистрации_обращений', type: 'EuolCounters' });
                    tabs.push({ name: 'Группы_исполнителей', type: 'ExecuterGroups' });
                    tabs.push({ name: 'Счетчики_поручений', type: 'OrdersCounters' });
                }
            }

            return tabs;
        },
        currentTab() {
            if (this.tab > this.tabs.length)
                return null;
            
            return this.tabs[this.tab];
        },
        menu() {
            let menu = Array.from(this.dataSource?.Menu ?? []);            
            
            if (!this.currentTab || !this.isViewMode)
                return menu;

            switch (this.currentTab.type) {
                case 'Structure':
                    if (this.employees.length == 0 && this.vacancys.length == 0)
                        menu.push({ Action: "ImportStruct", Icon: "import", Text: "Импорт", ToolTip: "Импорт_штатного_расписания" });
                    break;

                case 'Nomenclatures':
                    menu.push({ Action: "Create", Type: "Nomenclature", Icon: "add", Text: "Новая_номенклатура", ToolTip: "Добавить_новую_номенклатуру" });
                    menu.push({ Action: "ImportNomenclature", Type: "Nomenclature", Icon: "import", Text: "Импорт", ToolTip: "Импорт_номенклатур" });
                    break;

                case 'Counters':
                    menu.push({ Action: "Create", Type: "Counter", Icon: "add", Text: "Новый_журнал_регистрации", ToolTip: "Добавить_новый_журнал_регистрации" });
                    break;

                case 'EuolCounters':
                    menu.push({ Action: "Create", Type: "EuolCounter", Icon: "add", Text: "Новый_журнал_регистрации_обращений", ToolTip: "Добавить_новый_журнал_регистрации_обращений" });
                    break;

                case 'ExecuterGroups':
                    menu.push({ Action: "Create", Type: "ExecuterGroup", Icon: "add", Text: "Новая_группа_исполнителей", ToolTip: "Добавить_новую_группу_исполнителей" });
                    break;

                case 'OrdersCounters':
                    menu.push({ Action: "Create", Type: "OrdersCounter", Icon: "add", Text: "Новый_счетчик_поручений", ToolTip: "Добавить_новый_счетчик_поручений" });
                    break;

                default:
                    break;
            }

            return menu;
        },
        employees:{
            get: function() {
                return this.$store.getters['emsCards/enterpriseCard/getEmployees'];
            },
            set: function(value) {
                this.$store.commit('emsCards/enterpriseCard/SET_EMPLOYEES', value );
            }
        },
        vacancys:{
            get: function() {
                return this.$store.getters['emsCards/enterpriseCard/getVacancys'];
            },
            set: function(value) {
                this.$store.commit('emsCards/enterpriseCard/SET_VACANCYS', value );
            }
        },
        localizedName(){
            if (i18n.locale == 'ru')
                return this.dataSource.Enterprise.nameru;
            else if (this.dataSource.Enterprise.namekz && this.dataSource.Enterprise.namekz.trim() != '')
                return this.dataSource.Enterprise.namekz;
            else 
                return this.dataSource.Enterprise.nameru;
        },        
        isViewMode() {
            return this.dataSource?.FormId === 1301021;
        },
        isNewRecord() {
            return this.id === 'new';
        },
        filteredStruct() {
            let structCopy = JSON.parse(JSON.stringify(this.dataSource.Struct));

            if (!this.departmentsTreeSearch?.length)
                return structCopy;

            return filterTree(this.departmentsTreeSearch, structCopy);
        },
        //#region Tooltips
        selectDeputiesTooltip() {
            return this.$t("Выберите_заместителей");
        },
        selectHeadTooltip() {
            return this.$t("Выберите_руководителя");
        },
        selectSpecialPowerTooltip() {
            return this.$t("Выберите_сотрудников_с_особыми_полномочиями");
        }
        //#endregion
    },
    data() {
        return {
            //tab: 0,
            dataSource: null,
            departmentsTreeSearch: "",
            departmentsTreeActiveItem: null,
            departmentsTreeOpenAll: false,
            dlgSelectEmplyeeTitle: {
                0 : "Выбор_сотрудника",
                1 : "Выбор_руководителя",
                2 : "Выбор_заместителей",
                3 : "Выбор_сотрудников_с_полномочиями_руководителя",
            },
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('emsCards/enterpriseCard',['clearDragElements']),
        convertToListForSelecDialogSource(arr){
            if (!arr || arr.length == 0)
                return [];
            else 
                return arr.map(x =>[x.id, x.name ? x.name : x.dep ? `${x.dep} / ${x.jobtitle}` : x.jobtitle , null, x.name ? (x.dep ? `${x.dep} / ${x.jobtitle}` : x.jobtitle) : null]);    
        },
        async onEmployeeSelectDlg(selecttype){
            let selectElement = null;
            let sourceElements = null;
            switch(selecttype){
                case 0:
                    selectElement = [];
                    sourceElements = this.convertToListForSelecDialogSource(this.employees);
                    break;
                case 1:
                    selectElement = [this.dataSource.Enterprise.headid];
                    sourceElements = this.convertToListForSelecDialogSource(this.employees);
                    break;
                case 2:
                    selectElement = this.dataSource.Enterprise.deputiesids;
                    sourceElements = this.convertToListForSelecDialogSource(this.employees.filter(x=>x.id!=this.dataSource.Enterprise.headid));
                    break;
                case 3:
                    selectElement = this.dataSource.Enterprise.specpowerids;
                    sourceElements = this.convertToListForSelecDialogSource(this.employees.filter(x=>x.id!=this.dataSource.Enterprise.headid && !this.dataSource.Enterprise.deputiesids.find(z=>z==x.id)));
                    break;
            }
            let selectMemberParams =  {
                title: this.dlgSelectEmplyeeTitle[selecttype],
                fromSource: true,
                source: sourceElements,
                multiple: selecttype==2 || selecttype==3,
                selected: selectElement,
            }

            try
            {
                return await this.$store.dispatch('dialogs/selectMembers/open', selectMemberParams);
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onEmployeeSelect(selecthead){
            let selectedPerson = await this.onEmployeeSelectDlg(selecthead);
            if (selectedPerson){
                let idx = -1;
                switch(selecthead){
                    case 1:
                        this.dataSource.Enterprise.headid = selectedPerson.workplaceId;
                        idx = this.dataSource.Enterprise.deputiesids.indexOf(this.dataSource.Enterprise.headid);
                        if (idx >= 0){
                            this.dataSource.Enterprise.deputiesids.splice(idx, 1);
                        }
                        idx = this.dataSource.Enterprise.specpowerids.indexOf(this.dataSource.Enterprise.headid);
                        if (idx >= 0){
                            this.dataSource.Enterprise.specpowerids.splice(idx, 1);
                        }
                        break;
                    case 2:
                        this.dataSource.Enterprise.deputiesids = selectedPerson.map(x => x.workplaceId);
                        this.dataSource.Enterprise.specpowerids = this.dataSource.Enterprise.specpowerids.filter(x=>!this.dataSource.Enterprise.deputiesids.find(y=>y==x));
                        break;
                    case 3:
                        this.dataSource.Enterprise.specpowerids = selectedPerson.map(x => x.workplaceId); 
                        break;
            }
            }
        },
        async onHeadSelect(){
            await this.onEmployeeSelect(1);
        },
        async onDeputiesSelect(){
            await this.onEmployeeSelect(2);
        },
        async onSpecialPowerSelect(){
            await this.onEmployeeSelect(3);
        },
        async delHead(){
            this.dataSource.Enterprise.headid = '00000000-0000-0000-0000-000000000000';
        },
        async delDeputie(workplace_id){
            this.dataSource.Enterprise.deputiesids.splice(this.dataSource.Enterprise.deputiesids.indexOf(workplace_id), 1);
        },
        async delSpecPower(workplace_id){
            this.dataSource.Enterprise.specpowerids.splice(this.dataSource.Enterprise.specpowerids.indexOf(workplace_id), 1);
        },
        async getEnterprise(options = { edit: false }) {
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let url = '/api/ems/getenterprise';

            if (!this.isNewRecord)
                url += `?id=${this.id}&edit=${options.edit}`;

            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                if (options.edit) {
                    this.dataSource.Menu = response.data.payload.Menu;
                    this.dataSource.FormId = response.data.payload.FormId;
                    this.dataSource.Struct = response.data.payload.Struct;
                }
                else{
                    this.dataSource = response.data.payload;
                }
                await this.UpdateEmployeeList();
                await this.clearDragElements();
            }
        },
        async UpdateEmployeeList(){
            let employeeslist = [];
            let vacancylist = [];
            if (this.dataSource.Struct && this.dataSource.Struct.length > 0){
                this.getEmployees(employeeslist, vacancylist, this.dataSource.Struct[0]);
                this.employees = employeeslist;
                this.vacancys = vacancylist;
            }
        },
        getEmployees(emplist, vaclist, struct_element){
            let depname = '';
            if (struct_element.type=="Department")
                depname=struct_element.name;
            if (struct_element && struct_element.children){
                struct_element.children.forEach(element => {
                    switch (element.type)
                    {
                        case "Employee":
                            emplist.push({id:element.id, name:element.name, dep:depname, jobtitle:element.jobtitle});
                            break;
                        case "Workplace":
                            vaclist.push({id:element.id, dep:depname, jobtitle:element.jobtitle});
                            break;
                    }
                    if (element.children && element.children.length > 0)    
                        this.getEmployees(emplist, vaclist, element);
                });
            }
        },
        async saveEnterprise() {
            let validateResult = this.$refs?.form?.[0]?.validate();

            if (!validateResult) {
                this.$notify.alert(this.$t("Не_заполнены_обязательные_поля"));
                return;
            }

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: `/api/ems/saveenterprise`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content : JSON.stringify(this.dataSource.Enterprise) },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            return response;
        },
        //#region Действия (тулбар)
        async onToolbarClick(e, btn) {
            await this[btn.Action](btn);
        },
        async AddShema(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: `/api/ems/enterprisecreateshema?id=`+this.dataSource.Enterprise.id,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
            if (response) {
                await this.getEnterprise({ edit: false });
                this.$notify.success(response.data.payload.Message);
            }
        },
        async Save() {
            
            let response = await this.saveEnterprise();
            if (response) {

                if (this.isNewRecord)
                    this.$router.replace({ name: 'EMSEnterpriseCard', query: { id: response.data.payload.Data.Object }, params: { id: response.data.payload.Data.Object } }, await this.getEnterprise({ edit: true }));
                else
                    await this.getEnterprise({ edit: true });

                this.$notify.success(response.data.payload.Message);
            }
        },
        async SaveAndClose() {
            let response = await this.saveEnterprise();

            if (response) {
                if (this.isNewRecord)
                    this.$router.replace({ name: 'EMSEnterpriseCard', query: { id: response.data.payload.Data.Object }, params: { id: response.data.payload.Data.Object } });
                else
                    await this.getEnterprise({ edit: false });

                this.$notify.success(response.data.payload.Message);
            }
        },
        async Edit() {
            await this.getEnterprise({ edit: true });
        },
        async Create(btn) {
            switch (btn.Type)
            {
                case 'ExecuterGroup':
                {
                    try
                    {
                        let executerGroup = await this.$store.dispatch('dialogs/executersGroup/open', { groupId: null, enterpriseId: this.dataSource.Enterprise.id });
                        this.setOverlayVisible({ visible: true, message: 'Сохранение...' });

                        let saveResponse = await httpAPI({
                            url: `api/save?type=Documents.ExecuterGroup&entid=${this.dataSource.Enterprise.id}`,
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            data: { Content : JSON.stringify({ parent: executerGroup, childrens: null }) },
                        });
                        
                        this.setOverlayVisible({ visible: false });

                        if (saveResponse) {
                            let component = this.$refs['ExecuterGroups']?.[0]
            
                            if (component && typeof component.getData === 'function')
                                await component.getData();

                            this.$notify.success(saveResponse.data.message);
                        }
                    }
                    catch (ex)
                    {
                        console.log(ex);
                    }
                    break;
                }

                case 'OrdersCounter':
                {
                    try
                    {
                        let orderCounter = await this.$store.dispatch('dialogs/orderCounter/open', { counterId: null, enterpriseId: this.dataSource.Enterprise.id });
                        this.setOverlayVisible({ visible: true, message: 'Сохранение...' });

                        let saveResponse = await httpAPI({
                            url: `api/save?type=Orders.Counter&entid=${this.dataSource.Enterprise.id}`,
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            data: { Content : JSON.stringify({ parent: orderCounter, childrens: null }) },
                        });

                        this.setOverlayVisible({ visible: false });

                        if (saveResponse) {
                            let component = this.$refs['OrdersCounters']?.[0]
            
                            if (component && typeof component.getData === 'function')
                                await component.getData();

                            this.$notify.success(saveResponse.data.message);
                        }
                    }
                    catch (ex)
                    {
                        console.log(ex);
                    }
                    break;
                }

                default:
                {
                    try
                    {
                        this.setOverlayVisible({ visible: true });
                        await this.$router.push({ name: btn.Type, query: { id: 'new' }, params: { id: 'new', enterpriseId: this.dataSource.Enterprise.id } });
                    }
                    catch (err)
                    {
                        console.warn(err);
                    }
                    finally
                    {
                        this.setOverlayVisible({ visible: false });
                    }
                    break;
                }
            }
        },
        async ImportNomenclature(){
            try {
                await this.$refs.ImportNomenclatureRef.open(this.dataSource.Enterprise.id);
            } 
            catch (ex) {
                console.log(ex);
            }
        },
        async ImportStruct(){
            try {
                let result = await this.$refs.ImportStructRef.open(this.dataSource.Enterprise.id);
                if (result){
                    console.log(result);
                    await this.updateStruct(this.dataSource.Enterprise.id)
                }
            } 
            catch (ex) {
                console.log(ex);
            }
        },
        //#endregion
        //#region Работа с контактами
        async openContactEditDlg(contact) {
            try
            {
                await this.$refs.ContactsRef.open(contact);
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async onAddContact() {
            await this.openContactEditDlg(null);
        },        
        saveContact(contact) {            
            if (this.dataSource.Enterprise.contacts == null)
                this.dataSource.Enterprise.contacts = [];

            if (contact.isNew) {
                this.dataSource.Enterprise.contacts.push(contact);
            }
            else {
                let existedContact = this.dataSource.Enterprise.contacts.find(x => x.id === contact.id);

                if (existedContact) {
                    existedContact.value = contact.value;
                    existedContact.type = contact.type;
                    existedContact.description = contact.description;
                }
            }
        },
        editContact(contactId) {
            let existedContact = this.dataSource.Enterprise.contacts.find(x => x.id === contactId);

            if (existedContact)
                this.openContactEditDlg(existedContact)
        },
        deleteContact(contactId) {
            let existedContact = this.dataSource.Enterprise.contacts.find(x => x.id === contactId);

            if (existedContact)
                this.dataSource.Enterprise.contacts.splice(this.dataSource.Enterprise.contacts.indexOf(existedContact), 1);
        },
        //#endregion
        //#region Работа со структурой
        onDepartmentsTreeSelectedChange(selectedItem) {
            this.departmentsTreeActiveItem = selectedItem;
        },
        onDepartmentsTreeItemClick(/*clickedItem*/) {
            //console.log('onDepartmentsTreeItemClick', clickedItem);
        },
        async onDepartmentsTreeItemDblClick(clickedItem) {
            switch(clickedItem.type)
            {
                case 'Department': {
                    try
                    {
                        let department = await this.$store.dispatch('emsDialogs/addSubDivision/open', {
                            selected: clickedItem,
                            divisions: this.dataSource.Struct,
                            isNew: false
                        });

                        let response = await httpAPI({
                            url: `/api/ems/savedepartment`,
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            data: { Content : JSON.stringify({ 
                                id: clickedItem.id, 
                                nameru: department.name.ru, 
                                namekz: department.name.kz, 
                                parentid: department.parent, 
                                headid: department.head, 
                                deputiesids: department.deputiesids, 
                                specpowerids: department.specpowerids, 
                                index: department.index 
                                }) 
                            }
                        });
                        
                        if (response){
                            if (department.parent != clickedItem.parent_id)
                                this.updateStruct(this.dataSource.Struct[0].id);
                            else 
                                this.updateStruct(department.parent);
                            
                        }
                    }
                    catch(ex)
                    {
                        console.log(ex);
                    }
                    break;
                }
                case 'Workplace': {
                    try
                    {
                        let workplace = await this.$store.dispatch('emsDialogs/addWorkplace/open', {
                            selected: clickedItem,
                            divisions: this.dataSource.Struct,
                            isNew: false
                        });

                        let response = await httpAPI({
                            url: `/api/ems/saveworkplace`,
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            data: { Content : JSON.stringify({ id: clickedItem.id, jobtitleid: workplace.position.id, parentid: workplace.parent, index: workplace.index }) }
                        });
                        
                        if (response){
                            if (workplace.parent != clickedItem.parent_id)
                                this.updateStruct(this.dataSource.Struct[0].id);
                            else 
                                this.updateStruct(workplace.parent);
                        }
                    }
                    catch(ex)
                    {
                        console.log(ex);
                    }
                    break;
                }
                case 'Employee': {
                    this.$router.push({ name: 'EMSEmployeeCard', query: { id: clickedItem.id, isnew:false }, params: { id: clickedItem.id, isnew:true }});
                    break;
                }
                default:
                    break;
            }
        },
        async onDepartmentsTreeItemActionInvoked(action, item) {
            await this[action.Action](item);
        },
        toggleDepartmentsTree() {
            this.departmentsTreeOpenAll = !this.departmentsTreeOpenAll;
        },
        onDepartmentTreeSearchChanged: _.debounce(async function (searchValue) {
            this.departmentsTreeSearch = searchValue;

            if (this.departmentsTreeSearch)
                this.departmentsTreeOpenAll = true;
            else
                this.departmentsTreeOpenAll = false;
        }, 500),
        async onStructItemTransfer(fromWP, toWP){
            if (fromWP && fromWP.type=="Employee" && toWP && toWP.vacancy){
                let fromparent = await this.structSearchById(this.dataSource.Struct[0], fromWP.parent_id);
                let fromdescription = `${(this.dataSource.Struct[0].id != fromWP.parent_id ? `${fromparent.name} / ` : '')}${fromWP.jobtitle}`;
                let toparent = await this.structSearchById(this.dataSource.Struct[0], toWP.parent_id);
                let todescription = `${(this.dataSource.Struct[0].id != toWP.parent_id ? `${toparent.name} / ` : '')}${toWP.jobtitle}`;
                this.$notify.confirm(
                    `Вы хотите перевести сотрудника <b>${fromWP.name}</b> с рабочего места <b>${fromdescription}</b> на вакантное рабочее место <b>${todescription}</b>.<br>Сотрудник будет переведен с сохранением имеющегося логина и прав доступа.<br> Продолжить?`,
                    async () =>
                    {
                        this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
                        let response = await httpAPI({
                            url: `/api/ems/transferemployee?workplacefrom=${fromWP.id}&workplaceto=${toWP.id}`,
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' }
                        });
                    
                        if (response){
                            this.updateStruct(this.dataSource.Struct[0].id);
                        }
                        this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
                    },
                    () => { return; }
                );
            }
        },
        async TransferEmployee(selectedWorkPlace){
            if (selectedWorkPlace.vacancy){
                let selectedEmployee = null; 
                selectedEmployee = await this.onEmployeeSelectDlg(0);
                if (selectedEmployee){
                    let prevwp = await this.structSearchById(this.dataSource.Struct[0], selectedEmployee.workplaceId);
                    await this.onStructItemTransfer(prevwp, selectedWorkPlace);
                }
            }
        },
        async updateStruct(parentid) {
            let parent = await this.structSearchById(this.dataSource.Struct[0], parentid);
            let query_data = { id: parentid };

            if (this.departmentsTreeSearch != '')
                query_data.search = this.departmentsTreeSearch;

            if (parent) {
                let response = await httpAPI({
                    url: 'api/ems/departmentstree',
                    method: 'POST',
                    data: { Content : JSON.stringify(query_data) },//  query_data,
                });

                if (response){
                    parent.children = response?.data.payload;
                    await this.UpdateEmployeeList();
                }
            }
        },
        async structSearchById(root, search_id) {
            if (root.id === search_id)
                return root;

            if (root.children) {
                for (let child of root.children) {
                    let rez = await this.structSearchById(child, search_id)

                    if (rez)
                        return rez;
                }
            }
            return null;
        },
        async AddDepartment(selectedItem) {
            try
            {
                let newDepartment = await this.$store.dispatch('emsDialogs/addSubDivision/open', {
                    selected: selectedItem.id,
                    divisions: this.dataSource.Struct,
                    isNew: true
                });

                let response = await httpAPI({
                    url: `/api/ems/savedepartment`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ nameru: newDepartment.name.ru, namekz: newDepartment.name.kz, parentid: newDepartment.parent, index: newDepartment.index }) }
                });
                
                if (response)
                    this.updateStruct(newDepartment.parent);
            }
            catch(ex)
            {
                console.log(ex);
            }
        },
        async AddWorkplace(selectedItem) {
            try
            {
                let newWorkplace = await this.$store.dispatch('emsDialogs/addWorkplace/open', {
                    selected: selectedItem.id,
                    divisions: this.dataSource.Struct,
                    isNew: true
                });

                let response = await httpAPI({
                    url: `/api/ems/saveworkplace`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ jobtitleid: newWorkplace.position.id, parentid: newWorkplace.parent, index: newWorkplace.index }) }
                });
                
                if (response)
                    this.updateStruct(newWorkplace.parent);
            }
            catch(ex)
            {
                console.log(ex);
            }
        },
        async AddEmployee(selectedItem){
            this.$router.push({ name: 'EMSEmployeeCard', query: { id: selectedItem.id, isnew:true }, params: { id: selectedItem.id, isnew:true }});
        },
        async AddSubstitution(selectedItem){
            try {
                let todate = this.$moment(new Date()).add(1, 'year').format('YYYY-MM-DD')
                let substantein = await this.$refs.SetSubstanteinDlgRef.open(selectedItem.id, null, this.dataSource.Enterprise.id, todate);
                if (substantein){
                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
                    let response = await httpAPI({
                        url: '/api/ems/setsubstantein',
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        data: { Content : JSON.stringify({workplaceid:selectedItem.id, substantenworkplaceid:substantein.param.substanteinid, fromdate:substantein.param.from, todate:substantein.param.to}) },
                    });

                    this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

                    if (response) {
                        this.updateStruct(selectedItem.parent_id);
                        this.$notify.success(response.data.payload.Message);
                    }
                }
                
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async CancelActiveSubstitution(selectedItem){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });

            let response = await httpAPI({
                url: '/api/ems/cancelactivesubstantein?workplaceid='+selectedItem.id,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });

            if (response) {
                this.updateStruct(selectedItem.parent_id);
                this.$notify.success(response.data.payload.Message);
            }
        },
        //#endregion
        getEmployeeName(id) {
            return this.employees.find(x => x.id === id)?.name;
        },
        async onTabClicked(tabItem) {
            let component = this.$refs[tabItem.type]?.[0]
            
            if (component && typeof component.getData === 'function')
                await component.getData();
        }
    },
    created() {
        (async() => {
            await this.getEnterprise();
                
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();      
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    }
}
</script>