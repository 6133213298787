<template>

    <div class="div-table-rows-container">
        
        <div
            :draggable="item.type=='Employee' && item.id!=getUserInfo.workplaceId"
            :class="[isActive ? 'resol-tree-active' : '', 'div-table-row']"
            @dragstart="onDragStart"
            @dragend="onDragEnd"
            @dragover="onDragOver"
            @click.stop="handleRowClick(item)"
            @dblclick.stop="onItemDblClick(item)"
        >
            <!-- Ячейка Подразделение, должность-->
            <div class="div-table-row-cell" :style="{ 'width': `calc(20% - ${margin}px)`, 'margin-left': `${margin}px` }" >
                
                <div class="elipsis-text-wrap">
                    <span class="last-with-line" v-if="!isParent && margin != 0"></span>
                    <!--Toggle-->
                    <span v-if="isParent && margin != 0">
                        <i 
                            :class="[ isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right']"
                            @click.stop="toggle"
                            @dblclick.stop="() => {}"
                            style="cursor: pointer"
                        >
                        </i>
                    </span>
                    <span v-if="!isParent && level < 2" class="resol-tree-no-icon-area"></span>
                    <span v-if="!isParent && level >= 2" class="resol-tree-without-first-level-no-icon-area"></span>
                    <template>
                        <v-icon v-if="isDragOver" color="green darken-2" class="div-table-row-cell-icon">
                            mdi-arrow-right-bold
                        </v-icon>
                        <v-icon v-else-if="item.position==0" color="red darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Руководитeль_организации")'>
                            mdi-account-tie
                        </v-icon>
                        <v-icon v-else-if="item.position==1" color="red darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Заместитель_руководителя_организации")'>
                            mdi-account
                        </v-icon>
                        <v-icon v-else-if="item.position==2" color="green darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Руководитель_подразделения")'>
                            mdi-account-tie
                        </v-icon>
                        <v-icon v-else-if="item.position==3" color="green darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Заместитель_руководителя_подразделения")'>
                            mdi-account
                        </v-icon>
                        <v-icon v-else-if="item.type=='Employee'" color="darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Сотрудник")'>
                            mdi-account
                        </v-icon>
                        <v-icon v-else-if="item.type=='Workplace'" color="darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Рабочее_место")'>
                            mdi-account-outline
                        </v-icon>
                        <v-icon v-else-if="item.type=='Department'" color="darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Подразделение")'>
                            mdi-account-group
                        </v-icon>
                        <v-icon v-else-if="item.type=='Enterprise'" color="darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Организация")'>
                            mdi-office-building
                        </v-icon>
                        <!--<v-icon v-if="item.specpower" color="red darken-2" class="div-table-row-cell-icon" v-tooltip.top-center='$t("Особые-полномочия")'>
                            mdi-exclamation-thick
                        </v-icon>-->
                    </template>
                    <!--<div class="elipsis-text-wrap">-->
                        <div v-if="item.type=='Department' || item.type=='Enterprise'" class="etw-text-container" v-tooltip.top-start="item.name">
                            <span class="etw-text font-weight-bold" v-html="highlight(item.name)"></span>
                        </div>
                        <div v-else class="etw-text-container">
                            <span class="etw-text" v-html="highlight(item.jobtitle)"></span>
                        </div>
                    <!--</div>-->
                    
                </div>

            </div>

            <!-- Ячейка ФИО-->
            <div class="div-table-row-cell" style="width: 10%;">
                <div v-if="item.type=='Workplace' || item.type=='Employee'" class="elipsis-text-wrap">
                    <div v-if="item.vacancy" class="etw-text-container" v-tooltip.top-start="$t('ВАКАНСИЯ') + (shortFIO ? ' (' + shortFIO + ')':'')">
                        <span class="etw-text font-italic" v-html="highlight($t('ВАКАНСИЯ') + (shortFIO ? ' (' + shortFIO + ')':''))"></span>
                    </div>
                    <div v-else class="etw-text-container" v-tooltip.top-start="shortFIO">
                        <span class="etw-text" v-html="highlight(shortFIO)"></span>
                    </div>
                </div>
            </div>

            <!-- Ячейка Замещающий-->
            <div class="div-table-row-cell" style="width: 10%;">
                <div v-if="item.type=='Workplace' || item.type=='Employee'" class="elipsis-text-wrap">
                    <div class="etw-text-container" v-tooltip.top-start="normalizeFIO(item.substan)">
                        <span class="etw-text" v-html="highlight(normalizeFIO(item.substan))"></span>
                    </div>
                </div>
            </div>

            <!-- Ячейка Контакты-->
            <div class="div-table-row-cell" style="width: 27%;">
                <span v-if="(item.type=='Employee' || item.type=='Enterprise') && item.contacts">
                    <div class="onlyReadData more-per-lab-wrap">
                        <v-contact-item
                            v-for="contact in item.contacts"
                            :key="contact.id"
                            :type="contact.type === 'email' ? 'email' : 'phone'"
                            :title="contact.value"
                            :description="contact.description"
                            :hide-load="true"
                            :is-read-only="true"
                        />
                    </div>
                </span>
            </div>

            <!-- Ячейка Пользователь-->
            <div class="div-table-row-cell" style="width: 10%;">
                <div class="elipsis-text-wrap" v-if="item.type=='Employee' && item.login">
                    <v-icon
                        v-if="item.locked"
                        class="div-table-row-cell-icon"
                        v-tooltip.top-center='$t("Учетная_запись_заблокирована")'
                    >
                        fas fa-lock
                    </v-icon>
                    <div class="etw-text-container">
                        <span class="etw-text" v-html="highlight(item.login)"></span>
                    </div>
                </div>
            </div>

            <!-- Ячейка Роли-->
            <div class="div-table-row-cell" style="width: 15%;">
                <div class="elipsis-text-wrap" v-if="item.type=='Employee' && item.roles">
                    <div class="etw-text-container" v-tooltip.top-start="item.roles">
                        <span class="etw-text" v-html="highlight(item.roles)"></span>
                    </div>
                </div>
            </div>

            <!-- Ячейка Полномочия-->
            <div class="div-table-row-cell" style="width: 8%;">
                <div class="elipsis-text-wrap">
                    <div class="etw-text-container" v-tooltip.top-start="specpowerType">
                        <span class="etw-text" v-html="highlight(specpowerType)"></span>
                    </div>
                </div>
            </div>

            <!--Ячейка действий-->
            <div class="resol-tree-action-col" v-on:click.stop.prevent>
                <v-popover
                    v-if="item.actions.length > 0"
                    trigger="click"
                >
                    <v-chip
                        class="person-label"
                        small
                        label
                        color="orange lighten-3"
                    >
                        <v-icon color="#494F59">fas fa-ellipsis-v</v-icon>
                    </v-chip>

                    <template slot="popover">
                        <ul class="ribbon-button-submenu">
                            <li 
                                v-for="action in item.actions"
                                :key="action.action"
                                @click="onInvokeAction(action, item)"
                                v-close-popover
                            >
                                <v-icon left>
                                    {{ action.Icon }}
                                </v-icon>

                                {{ getActionText(action) }}
                            </li>
                        </ul>
                        <span class="ribbon-button-submenu-title">{{ $t("Подменю_действий") }}</span>
                    </template>
                </v-popover>
            </div>

        </div>
        <!--Рекурсия компонента-->
        <template v-if="isParent && isOpen">
            <DepartmentItem
                v-show="isOpen"
                v-for="child in item.children"
                :key="child.id"
                :item="child"
                :level="level + 1"
                :open-all="openAll"
                :active-id="activeId"
                :on-item-click="onItemClick"
                :on-item-dbl-click="onItemDblClick"
                :on-invoke-action="onInvokeAction"
                :on-item-transfer="onItemTransfer"
                :query="query"
            />
        </template>

    </div>

</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "DepartmentItem",
    props: {
        item: Object,
        level: {
            type: Number,
            default: 0
        },
        openAll: Boolean,
        activeId: String,
        onItemClick: Function,
        onItemDblClick: Function,
        onInvokeAction: Function,
        onItemTransfer: Function,
        query: String
    },
    data() {
        return {
            //isOpen: false,
            isRowDisabled: false,
            rowLastClickedDate: null
        }
    },
    async created() {
        if (this.level === 0 || this.openAll)
            this.$store.commit('emsCards/enterpriseCard/PUSH_OPEN_STRUCT_NODE', this.item.id );
        //this.isOpen = this.level === 0 ? true : this.openAll;
    },
    computed: {
        ...mapGetters('emsCards/enterpriseCard',{dragItem:"getDragStructElement"}),
        ...mapGetters('emsCards/enterpriseCard',{dragOverItem:"getDragOverStructElement"}),
        ...mapGetters('emsCards/enterpriseCard',{openNodes:"getOpenStructNodes"}),
        ...mapGetters('auth', ['getUserInfo']),
        isOpen() {
            return this.openNodes && this.openNodes.indexOf(this.item.id) >=0;
        },
        isParent() {
            return this.item.children != null;
        },
        isDragOver() {
            return this.dragOverItem && this.item.id==this.dragOverItem.id
        },
        margin() {
            return this.isParent && this.level > 0 ?  (this.level * 18) : this.level * 18;
        },
        isActive() {
            return this.item.id == this.activeId;
        },
        specpowerType() {
            if (this.item.specpower){
                switch (this.item.specpower){
                    case null: 
                        return '';
                    case '00000000-0000-0000-0000-000000000000':
                        return 'Руководитель_организации';
                    default:
                        return 'Руководитель_подразделения'  
                }
            }
            else 
                return '';
        },
        shortFIO(){
            return this.normalizeFIO(this.item.name);
        }
    },
    methods: {
        ...mapActions('emsCards/enterpriseCard',['clearDragElements']),
        toggle() {
            if (this.isParent){
                //this.isOpen = !this.isOpen;
                if (this.isOpen && this.item.type != "Enterprise")
                    this.$store.commit('emsCards/enterpriseCard/REMOVE_OPEN_STRUCT_NODE', this.item.id );
                else 
                    this.$store.commit('emsCards/enterpriseCard/PUSH_OPEN_STRUCT_NODE', this.item.id );
            }
            if (this.isOpen && this.isParent && this.item.children.length == 0)
                this.fetchDepts();  
        },
        normalizeFIO(fullFIO){
            if (!fullFIO)
                return "";
                
            let detailedName = fullFIO.split(' ');

            if (detailedName.length <= 0)
                return fullFIO;

            let resultName;
            detailedName.forEach(function(namePart, index) {
                if (index === 0){
                    resultName = namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase();
                }
                else
                    resultName += namePart[0] ? ` ${namePart[0]}.` : '';
            });
            return resultName;
        },
        getActionText(action){
            switch(action.Action){
                case 'AddDepartment':
                    return 'Добавить подразделение';
                case 'AddWorkplace':
                    return 'Добавить рабочее место';
                case 'AddEmployee':
                    return 'Назначить сотрудника';
                case 'TransferEmployee':
                    return 'Перевести сотрудника';
                case 'AddSubstitution':
                    return 'Назначить исполняющего обязанности';
                case 'CancelActiveSubstitution':
                    return 'Отменить активное замещение';
                default:
                    return action.Text
            }
        },
        async fetchDepts () {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: 'api/ems/departmentstree',
                method: 'POST',
                data: { Content : JSON.stringify({id: this.item.id}) },
                cancelToken: this.cancellationTokenSorce.token,
            });
            let childrens = response?.data.payload;
            this.item.children = this.item.children.concat(childrens);
        },
        highlight(content) {
            if (!this.query)
                return content;
            return String(content).replace(new RegExp(this.query, "gi"), match => {
                return `<span style="background:yellow">${match}</span>`;
            });
        },
        handleRowClick(rowItem) {
            this.onItemClick(rowItem);
            this.rowLastClickedDate = Date.now();

            if (this.isRowDisabled)
                return;
            
            this.isRowDisabled = true;

            setTimeout(() => {
                if (this.$moment(Date.now()).diff(this.rowLastClickedDate, 'ms') > 300)
                    this.toggle();

                this.isRowDisabled = false;
            }, 300);
        },
        onDragStart(){
            this.$store.commit('emsCards/enterpriseCard/SET_DRAG_STRUCT_ELEMENT', this.item );
        },
        onDragEnd(){
            if (this.dragItem && this.dragOverItem){
                this.onItemTransfer(this.dragItem, this.dragOverItem);
            }
            this.clearDragElements();
        },
        onDragOver(evt){
            if (this.item.vacancy && this.dragItem.type=='Employee'){
                this.$store.commit('emsCards/enterpriseCard/SET_DRAG_OVER_STRUCT_ELEMENT', this.item );
                evt.preventDefault();
            }
            else if (this.isParent && !this.isOpen){
                this.toggle();
            }
            else {
                this.$store.commit('emsCards/enterpriseCard/SET_DRAG_OVER_STRUCT_ELEMENT', null );
            }
        },
    },
    watch: {
        openAll(newValue) {
            if (this.level === 0 || newValue)
                this.$store.commit('emsCards/enterpriseCard/PUSH_OPEN_STRUCT_NODE', this.item.id );
            else if (!newValue)    
                this.$store.commit('emsCards/enterpriseCard/REMOVE_OPEN_STRUCT_NODE', this.item.id );
            //this.isOpen = this.level === 0 ? true : newValue;
        }
    },
}
</script>